import { Component, OnInit } from "@angular/core";
import { AuthGuardService } from "./../../servicios/auth-guard.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap/modal/modal";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import { LectorService } from "app/servicios/lector.service";
import { FormsModule } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import "rxjs/add/operator/take";
import { ToastrService } from "ngx-toastr";
import { UsuarioService } from "app/servicios/usuario.service";
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  usuario: string;
  tipo: string;
}

export const ROUTES: RouteInfo[] = [];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  usuario;
  modalRef: NgbModalRef;

  constructor(
    public ag: AuthGuardService,
    private modalService: NgbModal,
    private usuarioService: UsuarioService
  ) {}

  async ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    await this.ag.getAuth();
    this.usuario = this.ag.tipo;
  }

  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }

  async openModal(content) {
    this.modalRef = this.modalService.open(content, {
      size: "sm",
      centered: true,
    });
  }

  salir() {
    this.ag.logout();
    this.modalRef.close();
  }

  async delete(confirm) {
    const result = window.confirm(
      "¿Estás seguro que deseas eliminar tu cuenta?"
    );
    if (result) {
      await this.ag.delete();
      await this.usuarioService.deleteDoc(this.usuario.id);
      alert("Su cuenta fue eliminada");
      this.salir();
    }
  }
}
